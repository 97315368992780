<template>
  <van-cell-group inset>
    <template #title>
      <van-checkbox v-model="checkAll">
        {{ checkAll ? '取消' : '全选' }}
      </van-checkbox>
    </template>
    <van-checkbox-group v-model="checked" ref="checkboxGroup">
      <div v-for="(item, index) in dataList" :key="index">
        <van-cell>
          <template #icon>
            <van-checkbox :name="item.id">

            </van-checkbox>
          </template>
          <template #title>
            <p>{{ item.name }}</p>
          </template>

          <template #label>
            {{ item.des }}
          </template>

        </van-cell>


      </div>

    </van-checkbox-group>
  </van-cell-group>
</template>

<script>
export default {
  name: "CheckEditGird",


  data() {
    return {
      checked: [],
      checkAll: false,
      dataList: [
        {id: '111', name: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa', des: '01010100101001'},
        {id: '222', name: 'bbb', des: '01010100101001'},
        {id: '333', name: 'ccc', des: '01010100101001'},
        {id: '444', name: 'ddd', des: '01010100101001'},
        {id: '555', name: 'fff', des: '01010100101001'},
      ],
    }
  },

  watch: {
    checkAll: function (val) {
      this.$refs.checkboxGroup.toggleAll(val);
    }
  }

}
</script>

<style scoped>

</style>